<template>
  <button class="btn" :disabled="disabled">
    <img style="margin: auto" :src="icon" v-if="icon" />
    <p v-if="title" style="margin: auto" class="body-2-bold">
      {{ title }}
    </p>
  </button>
</template>

<script>
export default {
  name: "PrimaryButton",
  props: ["title", "disabled", "icon"]
};
</script>

<style lang="scss" scoped>
@import "../../../scss/v2/variables.scss";

.btn {
  display: flex;
  padding: 11px 10px;
  color: white;
  background-color: $ACTION_COLOR;
  line-height: 16px;
  border-radius: 12px;
  height: 40px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: $ACTION_COLOR_HOVER;
  }

  &:disabled {
    background-color: $ACTION_COLOR_INACTIVE;
    border: 0;
  }
}
</style>
