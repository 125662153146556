import { reasonsLost } from "../formHelpers";

function favouriteOptions() {
  return [
    {
      title: "Favourite",
      value: true
    },
    {
      title: "Not Favourite",
      value: false
    }
  ];
}

function trashOptions() {
  return [
    {
      title: "Trashed",
      value: "True"
    }
  ];
}

function sourceOptions() {
  return [
    {
      title: "Sighted",
      value: "Sighted"
    },
    {
      title: "Not Sighted",
      value: "Not Sighted"
    },
    {
      title: "Offsite",
      value: "Offsite"
    }
  ];
}

function ratingOptions() {
  return [
    {
      title: "1 Star",
      value: 1
    },
    {
      title: "2 Stars",
      value: 2
    },
    {
      title: "3 Stars",
      value: 3
    },
    {
      title: "4 Stars",
      value: 4
    },
    {
      title: "5 Stars",
      value: 5
    }
  ];
}

function tradedStatusOptions() {
  return [
    {
      title: "Traded",
      value: 2
    },
    {
      title: "Not Traded",
      value: 1
    },
    {
      title: "Not Traded, Not Lost",
      value: 4
    },
    {
      title: "Lost",
      value: 3
    }
  ];
}

function appraisalStatusOptions() {
  return [
    {
      title: "Pending - Sales",
      value: "pending_sales"
    },
    {
      title: "Pending - Management",
      value: "pending_management"
    },
    {
      title: "Pending",
      value: "Pending"
    },
    {
      title: "Active",
      value: "Active"
    },
    {
      title: "Complete",
      value: "Complete"
    }
  ];
}

function arrivedStatusOptions() {
  return [
    {
      title: "Arrived",
      value: true
    },
    {
      title: "Pending Arrival",
      value: false
    }
  ];
}

function intentionStatusOptions() {
  return [
    {
      title: "Wholesale",
      value: 0
    },
    {
      title: "Retail",
      value: 1
    },
    {
      title: "Undecided",
      value: 2
    },
    {
      title: "Budget",
      value: 3
    }
  ];
}

function readyForTradeOptions() {
  return [
    {
      title: "Ready",
      value: "True"
    },
    {
      title: "Not Ready",
      value: "False"
    }
  ];
}

function expiryOptions() {
  return [
    {
      title: "Expired",
      value: "True"
    },
    {
      title: "Not Expired",
      value: "False"
    }
  ];
}

function acceptedMarketingOptions() {
  return [
    {
      title: "Accepted",
      value: "True"
    },
    {
      title: "Denied",
      value: "False"
    }
  ];
}

function userRoleOptions() {
  return [
    {
      title: "Management",
      value: true
    },
    {
      title: "Sales",
      value: false
    }
  ];
}

function canRequestPPSROptions() {
  return [
    {
      title: "Yes",
      value: true
    },
    {
      title: "No",
      value: false
    }
  ];
}

function tenderStatusOptions() {
  return [
    {
      title: "Awaiting Start",
      value: 1
    },
    {
      title: "Ongoing",
      value: 2
    },
    {
      title: "Finished",
      value: 3
    }
  ];
}

function resellerActiveStatusOptions() {
  return [
    {
      title: "Active",
      value: true
    },
    {
      title: "Paused",
      value: false
    }
  ];
}

function filterOptions(dealerships, selectedDealerships, dealers) {
  return [
    {
      key: "car_registration_number",
      title: "Rego",
      type: "text",
      children: []
    },
    {
      key: "car_make",
      title: "Make",
      type: "text",
      children: []
    },
    {
      key: "car_model",
      title: "Model",
      type: "text",
      children: []
    },
    {
      key: "car_line",
      title: "Line",
      type: "text",
      children: []
    },
    {
      key: "car_chassis_number",
      title: "VIN",
      type: "text",
      children: []
    },
    {
      key: "car_colour",
      title: "Colour",
      type: "text",
      children: []
    },
    {
      key: "customer_name",
      title: "Customer name",
      type: "text",
      children: []
    },
    {
      key: "car_model_year",
      title: "Model Year",
      type: "range",
      children: [
        {
          title: "After",
          key: "car_model_year_gt",
          type: "number"
        },
        {
          title: "Before",
          key: "car_model_year_lt",
          type: "number"
        }
      ]
    },
    {
      key: "car_model_year_gt",
      title: "Model year younger than",
      type: "number",
      children: []
    },
    {
      key: "car_model_year_lt",
      title: "Model year older than",
      type: "number",
      children: []
    },
    {
      key: "car_mileage",
      title: "Mileage",
      type: "range",
      children: [
        {
          title: "Mileage greater than",
          key: "car_mileage_gt",
          type: "number"
        },
        {
          title: "Mileage less than",
          key: "car_mileage_lt",
          type: "number"
        }
      ]
    },
    {
      key: "car_mileage_gt",
      title: "Mileage greater than",
      type: "number",
      children: []
    },
    {
      key: "car_mileage_lt",
      title: "Mileage less than",
      type: "number",
      children: []
    },
    {
      key: "dealership",
      title: "Dealership",
      type: "children",
      children: calculateDealershipOptions(dealerships)
    },
    {
      key: "dealership_in",
      title: "Dealerships",
      type: "multi",
      children: calculateDealershipOptions(dealerships)
    },
    {
      key: "dealership_not",
      title: "Dealership not",
      type: "children",
      children: calculateDealershipOptions(dealerships)
    },
    {
      key: "department",
      title: "Department",
      type: "children",
      children: calculateDepartmentOptions(dealerships, selectedDealerships)
    },
    {
      key: "department_in",
      title: "Departments",
      type: "multi",
      children: calculateDepartmentOptions(dealerships, selectedDealerships)
    },
    {
      key: "department_not",
      title: "Department Not",
      type: "children",
      children: calculateDepartmentOptions(dealerships, selectedDealerships)
    },
    {
      key: "starting_dealer",
      title: "Started by",
      type: "children",
      children: calculateDealerOptions(dealers, selectedDealerships)
    },
    {
      key: "dealer",
      title: "Completed by",
      type: "children",
      children: calculateDealerOptions(dealers, selectedDealerships)
    },
    {
      key: "is_favourite",
      title: "Favourite",
      type: "children",
      children: favouriteOptions()
    },
    {
      key: "source",
      title: "Source",
      type: "children",
      children: sourceOptions()
    },
    {
      key: "reasons_lost",
      title: "Lost",
      type: "children",
      children: reasonsLost.map(rl => {
        return { title: rl, value: rl };
      })
    },
    {
      key: "is_trash",
      title: "Trashed",
      type: "children",
      children: trashOptions()
    },
    {
      key: "vehicle_rating",
      title: "Star rating",
      type: "children",
      children: ratingOptions()
    },
    {
      key: "traded_status",
      title: "Traded status",
      type: "children",
      children: tradedStatusOptions()
    },
    {
      key: "appraisal_status",
      title: "Appraisal status",
      type: "multi",
      children: appraisalStatusOptions()
    },
    {
      key: "traded_arrived",
      title: "Arrived",
      type: "children",
      children: arrivedStatusOptions()
    },
    {
      key: "traded_purpose",
      title: "Intention of trade",
      type: "children",
      children: intentionStatusOptions()
    },
    {
      key: "ready_for_traded",
      title: "Ready for trade",
      type: "children",
      children: readyForTradeOptions()
    },
    {
      key: "expired",
      title: "Expiry",
      type: "children",
      children: expiryOptions()
    },

    {
      key: "customer_remarketing_accepted",
      title: "Customer accepted remarketing",
      type: "children",
      children: acceptedMarketingOptions()
    },
    {
      key: "user_role",
      title: "User Role",
      type: "children",
      children: userRoleOptions()
    },
    {
      key: "can_request_ppsr",
      title: "Can Request PPSR",
      type: "children",
      children: canRequestPPSROptions()
    },
    {
      key: "tender_status",
      title: "Tender Status",
      type: "children",
      children: tenderStatusOptions()
    },
    {
      key: "reseller_active_status",
      title: "Active Status",
      type: "children",
      children: resellerActiveStatusOptions()
    }
  ];
}

function availableKeysForDealership(currentDealership, currentDealer) {
  var toReturn = [];

  toReturn.push("car_registration_number");
  toReturn.push("car_make");
  toReturn.push("car_model");
  toReturn.push("car_line");
  toReturn.push("car_chassis_number");
  toReturn.push("car_colour");
  toReturn.push("customer_name");
  toReturn.push("car_model_year");
  toReturn.push("car_mileage");
  if (currentDealer.is_super_manager) {
    toReturn.push("dealership_in");
    toReturn.push("dealership_not");
  }
  toReturn.push("department_in");
  toReturn.push("department_not");
  toReturn.push("starting_dealer");
  toReturn.push("dealer");
  toReturn.push("is_favourite");
  toReturn.push("reasons_lost");
  toReturn.push("is_trash");
  toReturn.push("source");
  toReturn.push("vehicle_rating");
  toReturn.push("traded_status");
  toReturn.push("appraisal_status");
  toReturn.push("traded_arrived");
  toReturn.push("traded_purpose");
  toReturn.push("ready_for_traded");
  toReturn.push("expired");
  if (currentDealer && (currentDealership.is_marketing_account || currentDealer.has_marketing_access)) {
    toReturn.push("customer_remarketing_accepted");
  }

  return toReturn;
}

function availableKeysForPendingArrivals(currentDealer) {
  var toReturn = [];

  toReturn.push("car_registration_number");
  toReturn.push("car_make");
  toReturn.push("car_model");
  toReturn.push("car_line");
  toReturn.push("car_chassis_number");
  toReturn.push("car_colour");
  toReturn.push("customer_name");
  toReturn.push("car_model_year");
  toReturn.push("car_mileage");
  if (currentDealer.is_super_manager) {
    toReturn.push("dealership_in");
    toReturn.push("dealership_not");
  }
  toReturn.push("department_in");
  toReturn.push("department_not");
  toReturn.push("traded_arrived");
  toReturn.push("traded_purpose");

  return toReturn;
}

function availableKeysForReseller() {
  var toReturn = [];

  toReturn.push("car_registration_number");
  toReturn.push("car_make");
  toReturn.push("car_model");
  toReturn.push("car_line");
  toReturn.push("car_chassis_number");
  toReturn.push("car_colour");
  toReturn.push("customer_name");
  toReturn.push("car_model_year");
  toReturn.push("car_mileage");
  toReturn.push("appraisal_status");

  return toReturn;
}

function availableKeysForPermissions(currentDealer) {
  var toReturn = [];

  if (currentDealer.is_super_manager) {
    toReturn.push("dealership");
    toReturn.push("dealership_not");
  }

  toReturn.push("user_role");
  toReturn.push("can_request_ppsr");

  return toReturn;
}

function availableKeysForDepartments(currentDealer) {
  var toReturn = [];

  if (currentDealer.is_super_manager) {
    toReturn.push("dealership");
    toReturn.push("dealership_not");
  }

  return toReturn;
}

function availableKeysForTender() {
  var toReturn = [];

  toReturn.push("tender_status");

  return toReturn;
}

function availableKeysForResellerList() {
  var toReturn = [];

  toReturn.push("reseller_active_status");

  return toReturn;
}

function calculateDealershipOptions(dealerships) {
  if (dealerships) {
    let dealershipOptions = [];
    dealerships.forEach(dealership => {
      dealershipOptions.push({
        title: dealership.first_name,
        value: dealership.id
      });
    });
    return dealershipOptions;
  }

  return [];
}

function calculateDepartmentOptions(dealerships, selectedDealerships) {
  if (dealerships) {
    let departmentOptions = [];
    if (selectedDealerships) {
      let ids = selectedDealerships.split(",");
      ids.forEach(selectedDealership => {
        let filteredDealership = dealerships.find(dealership => {
          return dealership.id == selectedDealership;
        });
        if (filteredDealership) {
          filteredDealership.departments.forEach(department => {
            departmentOptions.push({
              title: department.title,
              value: department.id
            });
          });
        }
      });
    } else if (dealerships.length == 1) {
      dealerships[0].departments.forEach(department => {
        departmentOptions.push({
          title: department.title,
          value: department.id
        });
      });
    } else {
      dealerships.forEach(dealership => {
        dealership.departments.forEach(department => {
          departmentOptions.push({
            title: department.title,
            value: department.id
          });
        });
      });
    }
    return departmentOptions;
  }
  return [];
}

function calculateDealerOptions(dealers, selectedDealerships) {
  if (dealers) {
    let dealerOptions = [];
    if (selectedDealerships) {
      let ids = selectedDealerships.split(",");
      ids.forEach(selectedDealership => {
        dealers.forEach(dealer => {
          if (selectedDealership == dealer.dealership && dealer.is_active) {
            dealerOptions.push({
              title: dealer.first_name + " " + dealer.last_name,
              value: dealer.id
            });
          }
        });
      });
    } else {
      dealers.forEach(dealer => {
        if (dealer.is_active) {
          dealerOptions.push({
            title: dealer.first_name + " " + dealer.last_name,
            value: dealer.id
          });
        }
      });
    }
    return dealerOptions;
  }
  return [];
}

function calculateFilters(filters, dealerships, dealers) {
  var calculatedFilters = [];
  for (const [key, value] of Object.entries(filters)) {
    var filter = filterOptions(dealerships, null, dealers).find(option => {
      return option.key == key;
    });
    if (filter) {
      switch (key) {
        case "dealership":
          {
            let dealership = dealerships.filter(dealership => dealership.id == value)[0];
            filter.value = dealership ? dealership.first_name : value;
          }
          break;
        case "dealership_not":
          {
            let dealership = dealerships.filter(dealership => dealership.id == value)[0];
            filter.value = dealership ? dealership.first_name : value;
          }
          break;
        case "dealership_in":
          {
            var toReturn = "";
            if (value) {
              let array = value.split(",");
              array.forEach(dealership_id => {
                let dealership = dealerships.filter(dealership => dealership.id == dealership_id)[0];
                toReturn += dealership ? dealership.first_name : value;
                toReturn += ", ";
              });
            }
            filter.value = toReturn;
          }
          break;
        case "department":
          {
            let department;
            dealerships.forEach(dealership => {
              let departmentSearch = dealership.departments.find(dep => {
                return dep.id == value;
              });
              if (departmentSearch) {
                department = departmentSearch;
              }
            });
            filter.value = department ? department.title : value;
          }
          break;
        case "department_not":
          {
            let department;
            dealerships.forEach(dealership => {
              let departmentSearch = dealership.departments.find(dep => {
                return dep.id == value;
              });
              if (departmentSearch) {
                department = departmentSearch;
              }
            });
            filter.value = department ? department.title : value;
          }
          break;
        case "department_in":
          {
            let toReturn = "";
            if (value) {
              let array = value.split(",");
              array.forEach(department_id => {
                dealerships.forEach(dealership => {
                  let departmentSearch = dealership.departments.find(dep => {
                    return dep.id == department_id;
                  });
                  if (departmentSearch) {
                    toReturn += departmentSearch ? departmentSearch.title : department_id;
                    toReturn += ", ";
                  }
                });
              });
            }
            filter.value = toReturn;
          }
          break;
        case "dealer":
          {
            let dealer = dealers.filter(dealer => dealer.id == value)[0];
            filter.value = dealer ? dealer.first_name + " " + dealer.last_name : value;
          }
          break;
        case "starting_dealer":
          {
            let dealer = dealers.filter(dealer => dealer.id == value)[0];
            filter.value = dealer ? dealer.first_name + " " + dealer.last_name : value;
          }
          break;
        case "is_favourite":
          {
            let status = favouriteOptions().filter(status => status.value == value)[0];
            filter.value = status ? status.title : value;
          }
          break;
        case "source":
          {
            let status = sourceOptions().filter(status => status.value == value)[0];
            filter.value = status ? status.title : value;
          }
          break;
        case "is_trash":
          {
            let status = trashOptions().filter(status => status.value == value)[0];
            filter.value = status ? status.title : value;
          }
          break;
        case "vehicle_rating":
          {
            let rating = ratingOptions().filter(rating => rating.value == value)[0];
            filter.value = rating ? rating.title : value;
          }
          break;
        case "appraisal_status":
          {
            let array = value.split(",");
            let toReturn = "";
            array.forEach(value => {
              let status = appraisalStatusOptions().filter(status => status.value == value)[0];
              toReturn += status ? status.title : value;
              toReturn += ", ";
            });
            filter.value = toReturn;
          }
          break;
        case "traded_status":
          {
            let status = tradedStatusOptions().filter(status => status.value == value)[0];
            filter.value = status ? status.title : value;
          }
          break;
        case "traded_arrived":
          {
            let status = arrivedStatusOptions().filter(status => status.value == value)[0];
            filter.value = status ? status.title : value;
          }
          break;
        case "traded_purpose":
          {
            let status = intentionStatusOptions().filter(status => status.value == value)[0];
            filter.value = status ? status.title : value;
          }
          break;

        case "ready_for_traded":
          {
            let status = readyForTradeOptions().filter(status => status.value == value)[0];

            filter.value = status ? status.title : value;
          }
          break;
        case "expired":
          {
            let status = expiryOptions().filter(status => status.value == value)[0];

            filter.value = status ? status.title : value;
          }
          break;
        case "customer_remarketing_accepted":
          {
            let status = acceptedMarketingOptions().filter(status => status.value == value)[0];

            filter.value = status ? status.title : value;
          }
          break;
        case "user_role":
          {
            let status = userRoleOptions().filter(status => status.value == value)[0];

            filter.value = status ? status.title : value;
          }
          break;
        case "can_request_ppsr":
          {
            let status = canRequestPPSROptions().filter(status => status.value == value)[0];

            filter.value = status ? status.title : value;
          }
          break;
        case "tender_status":
          {
            let status = tenderStatusOptions().filter(status => status.value == value)[0];

            filter.value = status ? status.title : value;
          }
          break;
        case "reseller_active_status":
          {
            let status = resellerActiveStatusOptions().filter(status => status.value == value)[0];

            filter.value = status ? status.title : value;
          }
          break;

        default:
          filter.value = value;
          break;
      }

      calculatedFilters.push(filter);
    }
  }
  return calculatedFilters;
}

export {
  tradedStatusOptions,
  appraisalStatusOptions,
  arrivedStatusOptions,
  intentionStatusOptions,
  calculateFilters,
  filterOptions,
  availableKeysForDealership,
  availableKeysForPendingArrivals,
  availableKeysForReseller,
  availableKeysForDepartments,
  availableKeysForTender,
  availableKeysForPermissions,
  availableKeysForResellerList
};
