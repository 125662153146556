<template>
  <div>
    <div id="search-bar" class="flex-row flex-grow margin-right-05">
      <img src="/assets/img/icon-search-basic.png" alt="" />
      <input
        class="no-button-style flex-grow fix-placeholder-color no-focus"
        type="text"
        :value="modelValue"
        @input="updateValue"
        :placeholder="placeholder"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchTextInput",
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    }
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/v2/variables.scss";
@import "../../scss/v2/base.scss";

#search-bar {
  background-color: $HEADER_GRAY_COLOR;
  padding: 4px 0px 4px 4px;
  height: 40px;
  border-radius: 12px;
}
</style>
