<template>
  <div>
    <div class="flex-row">
      <search-text-input v-model="searchText" :placeholder="placeholder" class="flex-grow margin-right-05" />
      <div class="position-relative">
        <secondary-button
          v-if="getFilters().length > 0"
          v-on:click="showFilter = !showFilter"
          class="width-120 vertical-flex-center"
          title="Filter"
          icon="/assets/img/icon-filter.svg"
          :selected="showFilter"
        />
        <div v-if="showFilter" class="dropdown shadow body-2 padding-vertical-025 max-height-350">
          <div
            class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
            v-for="filter in getFilters()"
            v-bind:key="filter"
            v-on:click="
              currentFilter = filter;
              showFilter = false;
            "
          >
            {{ filter.title }}
          </div>
        </div>
        <div v-if="currentFilter" class="dropdown shadow padding-1-05 min-width-320 flex-column">
          <div class="flex-row">
            <h4 class="h4 margin-right-auto vertical-flex-center">{{ currentFilter.title }}</h4>
            <button class="no-style" v-on:click="currentFilter = null"><img src="/assets/img/icon-close.svg" /></button>
          </div>
          <form @submit.prevent="applyFilter()" class="flex-column">
            <input
              v-model="inputVal"
              v-if="currentFilter.type == 'text' || currentFilter.type == 'number'"
              :type="currentFilter.type"
              :required="currentFilter.type == 'text' || currentFilter.type == 'number'"
              class="text-field margin-top"
              placeholder="Value"
            />
            <select
              :required="currentFilter.type == 'children'"
              v-model="optionVal"
              v-if="currentFilter.type == 'children'"
              class="dropdown-field margin-top-1-05"
            >
              <option value="">--- Select ---</option>
              <option v-for="child in currentFilter.children" v-bind:key="child" :value="child.value">
                {{ child.title }}
              </option>
            </select>
            <div class="margin-top-1-05" v-if="currentFilter.type == 'multi'">
              <div class="flex-row" v-for="child in currentFilter.children" v-bind:key="child">
                <input type="checkbox" v-model="multiVal" :value="child.value" :id="child.value" />
                <label :for="child.value" class="margin-left-05">{{ child.title }}</label>
              </div>
              <div class="flex-row" style="max-height: 1px; overflow: hidden">
                <input required type="checkbox" :checked="multiChecked" />
              </div>
            </div>
            <div class="margin-top-1-05 flex-row" v-if="currentFilter.type == 'range'">
              <div>
                <p class="label no-margin margin-bottom-025">{{ currentFilter.children[0].title }}</p>
                <input
                  v-model="rangeVal1"
                  :type="currentFilter.children[0].type"
                  :required="!rangeVal2"
                  class="text-field"
                  placeholder="Value"
                />
              </div>
              <div class="height-40 margin-top-auto margin-left margin-right flex-row">
                <p class="body-2 no-wrap vertical-flex-center">- {{ currentFilter.title }} -</p>
              </div>
              <div>
                <p class="label no-margin margin-bottom-025">{{ currentFilter.children[1].title }}</p>
                <input
                  v-model="rangeVal2"
                  :type="currentFilter.children[1].type"
                  :required="!rangeVal1"
                  class="text-field"
                  placeholder="Value"
                />
              </div>
            </div>
            <div class="flex-row">
              <primary-button title="Apply" class="margin-top margin-left-auto min-width-100" type="submit" />
            </div>
          </form>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import SecondaryButton from "../../Buttons/v2/SecondaryButton.vue";
import SearchTextInput from "../../Common/SearchTextInput.vue";
import { mapActions, mapGetters } from "vuex";
import {
  filterOptions,
  availableKeysForDealership,
  availableKeysForPendingArrivals,
  availableKeysForReseller,
  availableKeysForDepartments,
  availableKeysForTender,
  availableKeysForPermissions,
  availableKeysForResellerList
} from "../../../helpers/v2/filterHelpers.js";

import _ from "lodash";

export default {
  name: "SearchBar",
  props: {
    availableFilterMode: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "Search for car registration, make, model or customer name"
    },
    emitActions: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PrimaryButton,
    SecondaryButton,
    SearchTextInput
  },
  data: function () {
    return {
      searchText: "",
      showFilter: false,
      currentFilter: null,
      inputVal: "",
      optionVal: "",
      multiVal: [],
      rangeVal1: "",
      rangeVal2: ""
    };
  },
  methods: {
    getFilters: function () {
      var availableFilters = [];
      var availableOptions = [];
      var availableKeys = [];

      switch (this.availableFilterMode) {
        case "dealership":
          availableKeys = availableKeysForDealership(this.currentDealership, this.currentDealer);
          availableOptions = filterOptions(this.dealerships, this.getAddedFilters["dealership_in"], this.dealers);
          break;
        case "pending_arrivals":
          availableKeys = availableKeysForPendingArrivals(this.currentDealer);
          availableOptions = filterOptions(this.dealerships, this.getAddedFilters["dealership_in"], this.dealers);
          break;
        case "reseller":
          availableKeys = availableKeysForReseller();
          availableOptions = filterOptions();
          break;
        case "permissions":
          availableKeys = availableKeysForPermissions(this.currentDealer);
          availableOptions = filterOptions(this.dealerships, this.getAddedFilters["dealership_in"], this.dealers);
          break;
        case "departments":
          availableKeys = availableKeysForDepartments(this.currentDealer);
          availableOptions = filterOptions(this.dealerships, this.getAddedFilters["dealership_in"], this.dealers);
          break;
        case "tenders":
          availableKeys = availableKeysForTender(this.currentDealer);
          availableOptions = filterOptions(this.dealerships, this.getAddedFilters["dealership_in"], this.dealers);
          break;
        case "reseller_list":
          availableKeys = availableKeysForResellerList(this.currentDealer);
          availableOptions = filterOptions(this.dealerships, this.getAddedFilters["dealership_in"], this.dealers);
          break;
        default:
          break;
      }

      availableFilters = availableOptions.filter(filter => {
        return (
          availableKeys.filter(key => {
            return key == filter.key;
          }).length > 0
        );
      });

      return availableFilters.filter(filter => {
        if (filter.type == "range") {
          return !(filter.children[0].key in this.getAddedFilters && filter.children[1].key in this.getAddedFilters);
        }
        return !(filter.key in this.getAddedFilters);
      });
    },
    applyFilter: function () {
      var filters;
      if (this.currentFilter) {
        if (this.currentFilter.type == "children") {
          filters = [
            {
              filter: {
                key: this.currentFilter.key,
                value: this.optionVal
              }
            }
          ];
        } else if (this.currentFilter.type == "multi") {
          if (this.multiVal.length <= 0) {
            return;
          }
          filters = [
            {
              filter: {
                key: this.currentFilter.key,
                value: this.multiVal.toString()
              }
            }
          ];
        } else if (this.currentFilter.type == "range") {
          filters = [];
          if (this.rangeVal1) {
            filters.push({
              filter: {
                key: this.currentFilter.children[0].key,
                value: this.rangeVal1
              }
            });
          }
          if (this.rangeVal2) {
            filters.push({
              filter: {
                key: this.currentFilter.children[1].key,
                value: this.rangeVal2
              }
            });
          }
        } else {
          filters = [
            {
              filter: {
                key: this.currentFilter.key,
                value: this.inputVal
              }
            }
          ];
        }
      }

      filters.forEach(filter => {
        if (this.emitActions) {
          this.$emit("applyFilter", filter);
        } else {
          this.addFilter(filter);
        }
      });
      this.currentFilter = null;
      this.optionVal = "";
      this.inputVal = "";
      this.rangeVal1 = "";
      this.rangeVal2 = "";
      this.multiVal = [];
    },
    ...mapActions({
      getDealerships: "dealershipStore/fetchDealerships",
      addFilter: "formStore/addFilter",
      removeFilter: "formStore/removeFilter"
    })
  },
  computed: {
    multiChecked: function () {
      return this.multiVal.length > 0;
    },
    ...mapGetters({
      currentDealership: "dealershipStore/currentDealership",
      currentDealer: "dealershipStore/currentDealer",
      dealerships: "dealershipStore/dealerships",
      dealers: "dealershipStore/dealers",
      getAddedFilters: "formStore/getFilters"
    })
  },
  watch: {
    searchText: _.debounce(function (newVal) {
      const newFilter = {
        key: "quick_search",
        title: "Quick Search",
        value: newVal
      };
      if (this.emitActions) {
        this.$emit("applyFilter", { filter: newFilter });
      } else {
        if (newVal) {
          this.addFilter({ filter: newFilter });
        } else {
          this.removeFilter({ filter: newFilter });
        }
      }
    }, 600),
    currentFilter: function (newVal) {
      if (newVal) {
        if (newVal.type == "range") {
          this.rangeVal1 = this.getAddedFilters[newVal.children[0].key];
          this.rangeVal2 = this.getAddedFilters[newVal.children[1].key];
        }
      }
    }
  },
  mounted: function () {
    let app = this;

    if (this.getAddedFilters["quick_search"]) {
      this.searchText = this.getAddedFilters["quick_search"];
    }

    this.$nextTick(() => {
      if (this.availableFilterMode == "dealership") {
        app.getDealerships();
      }
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#search-bar {
  background-color: $HEADER_GRAY_COLOR;
  padding: 4px 0px 4px 4px;
  height: 40px;
  border-radius: 12px;
}

.no-style {
  border: none;
  background: none;
}

.highlight-on-hover:hover {
  background-color: $BACKGROUND_HOVER_COLOR;
}

.yellowBackground {
  background-color: $YELLOW_COLOR;
}

.dropdown {
  right: 0;
  left: auto;
}

.max-height-350 {
  max-height: 550px;
  overflow: scroll;
}
</style>
